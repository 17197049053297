import {number} from "echarts/src/export";
import { isPay, questionsSubmit } from '@/js/axios'
import {isPhone, isHKPhone, timesStr} from "./utils";

import {Toast} from 'vant'  //vant-UI的提示组件

import dayjs from "dayjs";

export default {
    name: "question2",
    data() {
        return {
            progressNum: 0,
            questionNum: 20,
            currentIndex: 0,
			pageIndex: 1,
            markShowNull: false,
            markShowError: false,
            radio: '',
            result: ['a', 'b'],
            swipeHeight: 0,
            errorTip: '',
			successShow: false,
			username:'',
            areaList: [
               {text: "江蘇省", code: '3200'},
               {text: "上海市", code: '3100'},
               {text: "北京市", code: '1100'},
               {text: "天津市", code: '1200'},
               {text: "浙江省", code: '3300'},
               {text: "廣東省", code: '4400'},
               {text: "遼寧省", code: '2100'},
               {text: "山東省", code: '3700'},
               {text: "內蒙古自治區", code: '1500'},
               {text: "重慶市", code: '5000'},
               {text: "福建省", code: '3500'},
               {text: "海南省", code: '4600'},
               {text: "湖北省", code: '4200'},
               {text: "湖南省", code: '4300'},
               {text: "陝西省", code: '6100'},
               {text: "河南省", code: '4100'},
               {text: "黑龍江省", code: '2300'},
               {text: "青海省", code: '6300'},
               {text: "雲南省", code: '5300'},
               {text: "安徽省", code: '3400'},
               {text: "四川省", code: '5100'},
               {text: "貴州省", code: '5200'},
               {text: "甘肅省", code: '6200'},
               {text: "江西省", code: '3600'},
               {text: "吉林省", code: '2200'},
               {text: "山西省", code: '1400'},
               {text: "河北省", code: '1300'},
               {text: "寧夏回族自治區", code: '6400'},
               {text: "廣西壯族自治區", code: '4500'},
               {text: "新疆維吾爾自治區", code: '6500'},
               {text: "西藏自治區", code: '5400'},
               {text: "中國香港", code: '4401'},
               {text: "中國臺灣", code: '0000'},
            ],

            height: '',
            weight: '',
            fat: '',
            // bust: '',
            waist: '',
            hip: '',
            area: '',
            drink: '',
            smoking: '',
            catering: '',
            vegetarian: '',
            sports: '',
            sleep: '',
            diseaseList: [
                {"kdis": "K01", "level": "0"},
                {"kdis": "K02", "level": "0"},
                {"kdis": "K03", "level": "0"},
                {"kdis": "K04", "level": "0"},
                {"kdis": "K05", "level": "0"},
                {"kdis": "K06", "level": "0"},
                {"kdis": "K07", "level": "0"},
                {"kdis": "K08", "level": "0"},
                {"kdis": "K09", "level": "0"},
                {"kdis": "K10", "level": "0"},
                {"kdis": "K11", "level": "0"},
                {"kdis": "K12", "level": "0"},
                {"kdis": "K13", "level": "0"},
            	
            	// 新增
            	{"kdis": "K14", "level": "0"},
            	{"kdis": "K15", "level": "0"},
            	{"kdis": "K16", "level": "0"},
            	{"kdis": "K17", "level": "0"},
            	{"kdis": "K18", "level": "0"},
            	{"kdis": "K19", "level": "0"},
            ],
            diseaseListAll: [
                {"kdis": "K01", "level": "0", "name": "高血壓"},
				{"kdis": "K02", "level": "0", "name": "高血脂"},
				{"kdis": "K03", "level": "0", "name": "糖尿病"},
				{"kdis": "K04", "level": "0", "name": "慢性肝病"},
				{"kdis": "K05", "level": "0", "name": "慢性腎臟病"},
				{"kdis": "K06", "level": "0", "name": "癌症"},
				{"kdis": "K07", "level": "0", "name": "肝硬化"},
				{"kdis": "K08", "level": "0", "name": "慢性阻塞性肺炎"},
				{"kdis": "K09", "level": "0", "name": "關節炎"},
				{"kdis": "K10", "level": "0", "name": "心臟病"},
				{"kdis": "K11", "level": "0", "name": "腦中風"},
				{"kdis": "K12", "level": "0", "name": "胃腸潰瘍"},
				{"kdis": "K13", "level": "0", "name": "睡眠障礙"},
			// 新增  健康评分
				{"kdis": "K14", "level": "0", "name": "貧血"},
				{"kdis": "K15", "level": "0", "name": "疼痛"},
				{"kdis": "K16", "level": "0", "name": "哮喘"},
				{"kdis": "K17", "level": "0", "name": "痛風"},
				{"kdis": "K18", "level": "0", "name": "痔瘡"},
				{"kdis": "K19", "level": "0", "name": "甲狀腺疾病"},
            ],
            disease: [],
            diseaseArr: [],
            diseaseShow: false,
            noSurgery: [],
            noRelativesDisease: [],
            examineList: [
                {"ne": "WBC", "ve": "", "ut": "A"},
                {"ne": "HGB", "ve": "", "ut": "A"},
                {"ne": "PLT", "ve": "", "ut": "A"},
                {"ne": "FG", "ve": "", "ut": "B"},
                {"ne": "HBA1C", "ve": "", "ut": "A"},
                {"ne": "CHOL", "ve": "", "ut": "B"},
                {"ne": "LDLC", "ve": "", "ut": "B"},
                {"ne": "HDLC", "ve": "", "ut": "B"},
                {"ne": "TG", "ve": "", "ut": "B"},
                {"ne": "GOT", "ve": "", "ut": "A"},
                {"ne": "GPT", "ve": "", "ut": "A"},
                {"ne": "ALP", "ve": "", "ut": "A"},
                {"ne": "ALB", "ve": "", "ut": "B"},
                {"ne": "CRE", "ve": "", "ut": "B"},
                {"ne": "UA", "ve": "", "ut": "B"},
                {"ne": "EGFR", "ve": "", "ut": "A"}
            ],
            showPicker: false,
            showPicker2: false,
            showPicker3: false,
            columns: [],
            phone: '',
            phoneCode: '',
            package: JSON.parse(sessionStorage.getItem('package'))|| 1,
			
			sex: '',//性别 体验版独有
			sexSel: false,//是否弹出性别选择器
			sexList: [ '男', '女' ],
			birth: '',//生日 体验版独有
			currentBirth: new Date('1990/1/1'),//默认显示时间
			birthSel: false,//是否弹出生日选择器
			
			submiting: false,
			
			stand: false ,//测量标准
			
			showPopover: false,
			showPopoverHip: false,
			hasD: false,
			savedBirth: '',
        }
    },
    created() {
		this.jumpQuest();
		
        this.currentIndex= 0;
        document.title = '調查問卷';
		
		let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : '';
		if(questionData) {
			this.sex = questionData.sex == '1' ? '男' : '女';
			// this.birth = questionData.birth ? questionData.birth : '';
			this.height = questionData.height ? questionData.height : '';
			this.weight = questionData.weight ? questionData.weight : '';
			this.fat = questionData.fat ? questionData.fat : '';
			this.chest = questionData.chest ? questionData.chest : '';
			this.waist = questionData.waist ? questionData.waist : '';
			this.hip = questionData.hip ? questionData.hip : '';
			this.drink = questionData.drink ? questionData.drink + '' : '';
			this.smoking = questionData.smoke ? questionData.smoke + '' : '';
			this.catering = questionData.habeat ? questionData.habeat + '' : '';
			this.vegetarian = questionData.vege ?  questionData.vege + '' : '';
			this.sports = questionData.habsport ? questionData.habsport + '' : '';
			this.sleep = questionData.sleep ? questionData.sleep + '' : '';
			// this.disease = questionData.disease
			this.noSurgery = questionData.oper ? questionData.oper : [];
			this.noRelativesDisease = questionData.famdis ? questionData.famdis : [];
			this.area = questionData.area ? questionData.area : '';
			
			this.diseaseList = questionData.disease && questionData.disease.length > 0 ? questionData.disease : this.diseaseList;
			if(questionData.disease && questionData.disease.length > 0) {
				questionData.disease.map((item, index) => {
					if(item.level != 0) {
						this.disease.push(item.kdis)
					}
				})
			}
		
		}
    },
    mounted() {
		
		this.swiperHeightChange();
		window.addEventListener('resize', this.swiperHeightChange);

    },
    methods: {
		swiperHeightChange() {
			//微信打开全屏,否则去除头部
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			
			this.swipeHeight = clientHeight - (this.$store.state.isWxOpen?'': (0.91 * 100 * ratio / 2));
			// this.swipeHeight = document.documentElement.clientHeight - (this.$store.state.isWxOpen?'':47);
		},
		why() {
			this.markShowError = true;
			this.errorTip = `<p>體型是健康的重要指標之一。體型數據的失衡，往往是內在核心生化指標發生重要改變的外在表現，更是慢性病發生的先兆。臨床研究指出，肥胖或過重易引發四高疾病等長期慢性疾病。</p>
							<br>
							<p>我們依據逾2500萬亞洲人的健康大數據，通過與同健康族群者的數據比較，結合與臨床標準的比較，可為您預測健康風險，並提出健康管理的目標與建議。</p>
							<br>
							<p>因此，請您儘可能準確測量、填寫您的體型信息。</p>`;
		},
        onConfirm(value) {
            this.bookingArea = value;
            this.showPicker = false;
        },
        onConfirm2(value) {
            this.bookingArea2 = value;
            this.showPicker2 = false;
        },
        onConfirm3(value) {
            this.area = value;
            this.showPicker3 = false;
        },
        close() {
            this.markShowNull = false;
            this.markShowError = false;
			this.successShow = false
        },
        onClickLeft() {
            this.$router.back(-1)
        },
        backHome() {
            this.$router.push({path: '/aizh'})
        },
        onChange(index) {
            Toast('当前 Swipe 索引：' + index);
        },
        jumpQuest() {
			this.$nextTick(() => {
				this.$refs.nextSwipe.swipeTo(this.currentIndex, {
					immediate: true
				});
				// this.currentIndex = 1
				//进度条(原本问卷页数量写死,改为检测页数数量)
				//this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100;
				this.progressNum = ((this.currentIndex + 1) / document.querySelectorAll('.van-swipe-item').length) * 100;
				
				//切换效果
				var target = document.querySelectorAll('.van-swipe-item')[this.currentIndex];
				var prevToggle = document.querySelector('.toggle-question');
				prevToggle?prevToggle.classList.remove('toggle-question'):'';
				target.className += ' toggle-question';
			});
        },
        isGo() {
            this.currentIndex++;
            this.jumpQuest()
        },
        
        nextQuest(isGo) {
			//顶头开始
			window.scrollTo(0, 0);
			if(isGo != 15) {
				this.pageIndex++
			}
			
            let _that = this;
            // this.isGo();
			if (isGo === 0){//体验版独有
				if(this.sex == ''){
					this.markShowError = true;
					this.errorTip = '請選擇性別'
				}else if(this.birth == ''){
					this.markShowError = true;
					this.errorTip = '請選擇您的出生日期'
				}else{
					let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
					let data = {
						...questionData,
						sex: this.sex == '男' ? '1' : '2',
						birth: this.birth
					}
					localStorage.setItem('aiquestionData', JSON.stringify(data))
					this.isGo();
				}
				
			}else if (isGo === 1) {
                if (this.height == '' || this.height < 130 || this.height > 200) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適身高，建議範圍：130~200'
                } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體重，建議範圍：30~160'
                } else if (this.fat != '' && this.fat < 3 || this.fat > 75) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體脂率，建議範圍：3~75';
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	height: this.height,
                    	weight: this.weight,
                    	fat: this.fat,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 2) {
				if (this.waist == '' || this.waist < 40 || this.waist > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
				} else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
                        this.markShowError = true;
                        this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	waist: this.waist,
                    	hip: this.hip,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 3) {

                if (this.area == '' || this.area == '' || typeof area == "number") {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的居住地'
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	area: this.area,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 4) {
				this.isGo()
            } else if (isGo === 5) {
                 this.isGo()
            } else if (isGo === 6) {
                if (this.drink == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否喝酒'
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	drink: this.drink,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 7) {
                if (this.smoking == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否吸煙'
                } else {
                   let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                   let data = {
                   	...questionData,
                   	smoke: this.smoking,
                   }
                   localStorage.setItem('aiquestionData', JSON.stringify(data))
                   this.isGo()
                }

            } else if (isGo === 8) {
                if (this.catering == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的用餐習慣'
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	habeat: this.catering,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 9) {
                if (this.vegetarian == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否是全日素食者'
                } else {
                   let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                   let data = {
                   	...questionData,
                   	vege: this.vegetarian,
                   }
                   localStorage.setItem('aiquestionData', JSON.stringify(data))
                   this.isGo()
                }

            } else if (isGo === 10) {
                if (this.sports == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的運動習慣'
                } else {
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	habsport: this.sports,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 11) {
                if (this.sleep == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否有睡眠障礙'
                } else {
                   let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                   let data = {
                   	...questionData,
                   	sleep: this.sleep,
                   }
                   localStorage.setItem('aiquestionData', JSON.stringify(data))
                   this.isGo()
                }

            } else if (isGo === 12) {
                if (this.disease.length > 0) {
					this.hasD = true
                    this.diseaseShow = true
                } else {
					this.hasD = false
					this.pageIndex++
                    this.diseaseShow = false
                }
                let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                let data = {
                	...questionData,
                	disease: this.diseaseList,
                }
                localStorage.setItem('aiquestionData', JSON.stringify(data))
                // return ;
                this.isGo()
            } else if (isGo === 13) {
                let _that = this;
				
                try {
                    this.diseaseArr.forEach(function (item, index) {
                        if (item.level == '0') {
                            _that.markShowError = true;
                            _that.errorTip = '請選擇' + item.name + '用藥情况!';
                            throw new Error("EndIterative")
                        } else {
                            _that.diseaseList.forEach(function (itemL, number) {
                                if (item.kdis == itemL.kdis) {
                                    _that.diseaseList[number].level = item.level
                                }
                            });

                        }
                    });
					
                    let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
                    let data = {
                    	...questionData,
                    	disease: _that.diseaseList,
                    }
                    localStorage.setItem('aiquestionData', JSON.stringify(data))
                    _that.isGo()
                } catch (e) {
                    console.log('END')
                }

            } else if (isGo === 14) {
				let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
				let data = {
					...questionData,
					oper: this.noSurgery,
				}
				localStorage.setItem('aiquestionData', JSON.stringify(data))
				this.isGo()
            } else if (isGo === 15) {
				let questionData = localStorage.getItem('aiquestionData') ? JSON.parse(localStorage.getItem('aiquestionData')) : {}
				let data = {
					...questionData,
					famdis: this.noRelativesDisease,
				}
				localStorage.setItem('aiquestionData', JSON.stringify(data))
				this.handleToPay();
            }
        },
		handleToPay() {
			let goods_id = sessionStorage.getItem('package') ? sessionStorage.getItem('package') : ''
			let data = {
				sex: this.sex == '男'?1:(this.sex == '女'?2:''),
				birth: this.birth.split('/').join(''),
			    height: this.height,
			    weight: this.weight,
			    fat: this.fat,
			    // bust: this.bust,
			    waist: this.waist,
			    hip: this.hip,
			    area: this.area.code,
			    drink: this.drink,
			    smoke: this.smoking,
			    habeat: this.catering,
			    vege: this.vegetarian,
			    habsport: this.sports,
			    sleep: this.sleep,
			    disease: this.diseaseList,
			    oper: this.noSurgery,
			    famdis: this.noRelativesDisease
			};
			data.goods_id = goods_id
			
			questionsSubmit(data).then((res) => {
				this.submiting = false;
				// console.log(res)
				if(res.code == 200){
					let resT = JSON.parse(window.atob(res.data));
					Toast.success(res.msg);
					// localStorage.removeItem('questionData');
					// 清除本地存储输入内容
					sessionStorage.setItem('orderid', resT.id)
					this.$router.push({
						path: '/aizh/payment/0'
					})
				}else{
					Toast.fail(res.msg);
				}
			});
			
			
		},
		
        prevQuest() {
            this.currentIndex--;
			if(this.hasD){
				this.pageIndex--
			} else if(!this.hasD && this.pageIndex == 13) {
				this.pageIndex = this.pageIndex - 2
			} else { 
				this.pageIndex--
			}
            this.jumpQuest()
        },
        tipsMark() {
            this.markShowError = true;
            this.errorTip = "<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>"
        },
        
        filter(type, options) {
            if (type === 'minute') {
                return options.filter(option => option % 15 === 0)
            }
            if (type === 'hour') {
                return options.filter(option => option < 13 && option > 8)
            }
            return options;
        },
		//体验版独有
		//性别选择
		sexConfirm(value) {
			this.sex = value;
			this.sexSel = false;
		},
		formatter(type, val) {
		  if (type === "year") {
		    return val + "年";
		  }
		  if (type === "month") {
		    return val + "月";
		  }
		  if (type === "day") {
		    return val + "日";
		  }
		  return val;
		},
		//生日选择
		birthConfirm(value) {
			console.log(value)
			var language = navigator.language;
			if (language.toLocaleLowerCase().indexOf("zh-cn") >= 0) {
			  this.birth = dayjs(value).format("YYYY/MM/DD");
			} else {
			  this.birth = dayjs(value).format("YYYY/MM/DD");
			}
			this.savedBirth = dayjs(value).format("YYYY/MM/DD");
			
			this.birthSel = false;
		},
		limitDate(year) {//参数：距今多少年
			let now = new Date();
			return new Date((now.getFullYear()-year)+'/'+(now.getMonth()+1)+'/'+now.getDate());
		}
    },
	computed: {
		//年龄为20-90岁之间(直接绑定在组件上会卡死)
		birthMinDate (){
			return this.limitDate(90);
		},
		birthMaxDate (){
			return this.limitDate(20);
		}
	},
    watch: {
        disease(newVal, oldVal) {
            if (newVal.length > 0) {
                let _that = this;
                _that.diseaseArr = [];
                this.disease.forEach(function (item, index) {
                    _that.diseaseListAll.forEach(function (itemL, number) {
                        if (item == itemL.kdis) {
                            _that.diseaseArr.push(_that.diseaseListAll[number]);
                        }
                    })
                });
				_that.diseaseList.forEach(function (item, index) {
					_that.diseaseArr.forEach(function (itemL, number) {
					    if (item.kdis == itemL.kdis) {
					        itemL.level = item.level
					    }
					})
				})
                this.questionNum = 16;
            } else {
				this.diseaseList = [
					{"kdis": "K01", "level": "0"},
					{"kdis": "K02", "level": "0"},
					{"kdis": "K03", "level": "0"},
					{"kdis": "K04", "level": "0"},
					{"kdis": "K05", "level": "0"},
					{"kdis": "K06", "level": "0"},
					{"kdis": "K07", "level": "0"},
					{"kdis": "K08", "level": "0"},
					{"kdis": "K09", "level": "0"},
					{"kdis": "K10", "level": "0"},
					{"kdis": "K11", "level": "0"},
					{"kdis": "K12", "level": "0"},
					{"kdis": "K13", "level": "0"},
					{"kdis": "K14", "level": "0"},
					{"kdis": "K15", "level": "0"},
					{"kdis": "K16", "level": "0"},
					{"kdis": "K17", "level": "0"},
					{"kdis": "K18", "level": "0"},
					{"kdis": "K19", "level": "0"}
				]
            }
        }
    },
	destoryed() {
		window.removeEventListener('resize', this.swiperHeightChange);
	}
}
